import AccountNotificationIcon from "@/assets/svg/nav/account_notifications.svg"
import AdminIcon from "@/assets/svg/nav/admins.svg"
import BrandIcon from "@/assets/svg/nav/brands.svg"
import CategoryIcon from "@/assets/svg/nav/categories.svg"
import CountryIcon from "@/assets/svg/nav/countries.svg"
import CourierIcon from "@/assets/svg/nav/couriers.svg"
import CurrencyIcon from "@/assets/svg/nav/currencies.svg"
import DashboardIcon from "@/assets/svg/nav/dashboard.svg"
import DriverIcon from "@/assets/svg/nav/drivers.svg"
import ExportIcon from "@/assets/svg/nav/exports.svg"
import GenerateReportIcon from "@/assets/svg/nav/generate_report.svg"
import ImportIcon from "@/assets/svg/nav/import.svg"
import IssueIcon from "@/assets/svg/nav/issues.svg"
import LocaleIcon from "@/assets/svg/nav/locales.svg"
import LogisticIcon from "@/assets/svg/nav/logistics.svg"
import MediaIcon from "@/assets/svg/nav/media.svg"
import MessageIcon from "@/assets/svg/nav/messages.svg"
import OrderIcon from "@/assets/svg/nav/orders.svg"
import PaymentMethodIcon from "@/assets/svg/nav/payment_method.svg"
import PaymentProviderIcon from "@/assets/svg/nav/payment_provider.svg"
import PrivacyPolicyIcon from "@/assets/svg/nav/privacy_policy.svg"
import ProductConfigIcon from "@/assets/svg/nav/product_config.svg"
import ProductIcon from "@/assets/svg/nav/products.svg"
import PromoIcon from "@/assets/svg/nav/promocode.svg"
import RibbonIcon from "@/assets/svg/nav/ribbon.svg"
import RoleIcon from "@/assets/svg/nav/roles.svg"
import SentNotificationIcon from "@/assets/svg/nav/send_notification.svg"
import SettingsIcon from "@/assets/svg/nav/settings.svg"
import ShippingProviderIcon from "@/assets/svg/nav/shipping_provider.svg"
import ShopPageIcon from "@/assets/svg/nav/shop_page.svg"
import TermsCondIcon from "@/assets/svg/nav/terms_cond.svg"
import UnitCategoryIcon from "@/assets/svg/nav/unit_categories.svg"
import UserIcon from "@/assets/svg/nav/users.svg"
import VariantIcon from "@/assets/svg/nav/variants.svg"
import ViewReportIcon from "@/assets/svg/nav/view_report.svg"
import ZoneIcon from "@/assets/svg/nav/zones.svg"

let adminMenu = [
  {
    title: 'dashboard',
    to: { name: 'index' },
    icon: { icon: DashboardIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'dashboard.get',
  },

  // General
  { heading: 'general' },
  {
    title: 'users',
    to: { name: 'users' },
    icon: { icon: UserIcon },
    is_custom: true,
    perm: 'user',
  },
  {
    title: 'orders',
    to: { name: 'orders' },
    icon: { icon: OrderIcon },
    is_custom: true,
    perm: 'order',
  },
  {
    title: 'exports',
    to: { name: 'exports' },
    icon: { icon: ExportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'export',
  },
  {
    title: 'imports',
    to: { name: 'imports' },
    icon: { icon: ImportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'import',
  },
  
  // Report
  { heading: 'reports' },
  {
    title: 'generateReports',
    to: { name: 'generate-report' },
    icon: { icon: GenerateReportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'report_query',
  },
  {
    title: 'viewReports',
    to: { name: 'view-report' },
    icon: { icon: ViewReportIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'report_view',
  },

  // Operations
  { heading: 'operations' },
  {
    title: 'reportedIssues',
    to: { name: 'issues' },
    icon: { icon: IssueIcon },
    is_custom: true,
    perm: 'issue',
  },
  {
    title: 'predefinedIssues',
    to: { name: 'predefined-issues' },
    icon: { icon: IssueIcon },
    is_custom: true,
    perm: 'predefined_issue',
  },
  {
    title: 'messages',
    to: { name: 'contact-us' },
    icon: { icon: MessageIcon },
    is_custom: true,
    perm: 'contact_us',
  },
  
  // Product
  { heading: 'product' },
  {
    title: 'products',
    to: { name: 'products' },
    icon: { icon: ProductIcon },
    is_custom: true,
    perm: 'product',
  },
  {
    title: 'productConfigs',
    icon: { icon: ProductConfigIcon },
    is_custom: true,
    children: [
      {
        title: 'categories',
        to: { name: 'categories' },
        icon: { icon: CategoryIcon },
        is_custom: true,
        perm: 'category',
      },
      {
        title: 'brands',
        to: { name: 'brands' },
        icon: { icon: BrandIcon },
        is_custom: true,
        perm: 'brand',
      },
      {
        title: 'ribbons',
        to: { name: 'ribbons' },
        icon: { icon: RibbonIcon },
        is_custom: true,
        perm: 'ribbon',
      },
      {
        title: 'variants',
        to: { name: 'variants' },
        icon: { icon: VariantIcon },
        is_custom: true,
        perm: 'variant',
      },
    ],
  },

  // Notification
  { heading: 'notification' },
  {
    title: 'accountNotifications',
    to: { name: 'notifications' },
    icon: { icon: AccountNotificationIcon },
    is_custom: true,
  },
  {
    title: 'sendNotifications',
    to: { name: 'send-notifications' },
    icon: { icon: SentNotificationIcon },
    is_custom: true,
    perm: 'send_notification',
  },

  // accounts
  { heading: 'accounts' },
  {
    title: 'admins',
    to: { name: 'admins' },
    icon: { icon: AdminIcon },
    is_custom: true,
    perm: 'admin',
  },
  {
    title: 'drivers',
    to: { name: 'drivers' },
    icon: { icon: DriverIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'driver',
  },
  {
    title: 'couriers',
    to: { name: 'couriers' },
    icon: { icon: CourierIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'courier',
  },
  {
    title: 'rolesAndPermissions',
    to: { name: 'roles-and-permissions' },
    icon: { icon: RoleIcon },
    is_custom: true,
    perm: 'role',
  },

  // Logistics
  { heading: 'delivery' },
  {
    title: 'logistics',
    to: { name: 'logistics' },
    icon: { icon: LogisticIcon },
    is_custom: true,
    fillCls: 'svg-fill-transperant',
    perm: 'order.logistic',
  },
  {
    title: 'shippingProvider',
    to: { name: 'shipping-providers' },
    icon: { icon: ShippingProviderIcon },
    is_custom: true,
    perm: 'shipping_provider',
  },
  
  // Payments
  { heading: 'payments' },
  {
    title: 'paymentMethod',
    to: { name: 'payment-method' },
    icon: { icon: PaymentMethodIcon },
    is_custom: true,
    perm: 'payment_method',
  },
  {
    title: 'paymentProvider',
    to: { name: 'payment-providers' },
    icon: { icon: PaymentProviderIcon },
    is_custom: true,
    perm: 'payment_provider',
  },

  // Configurations
  { heading: 'configurations' },
  {
    title: 'promotionalCodes',
    to: { name: 'promo-codes' },
    icon: { icon: PromoIcon },
    is_custom: true,
  },
  {
    title: 'locationsAndZones',
    to: { name: 'locations' },
    icon: { icon: ZoneIcon },
    is_custom: true,
    perm: 'location',
  },
  {
    title: 'mediaLibrary',
    to: { name: 'media' },
    icon: { icon: MediaIcon },
    is_custom: true,
    perm: 'attachment',
  },

  // page builder
  { heading: 'pageBuilder' },
  {
    title: 'shopPage',
    to: { name: 'shop-page' },
    icon: { icon: ShopPageIcon },
    is_custom: true,
    perm: 'home_page.get',
  },
  {
    title: 'privacyPolicy',
    to: { name: 'privacy-policy' },
    icon: { icon: PrivacyPolicyIcon },
    is_custom: true,
    perm: 'settings.privacy_policy',
  },
  {
    title: 'termsAndCondition',
    to: { name: 'terms-cond' },
    icon: { icon: TermsCondIcon },
    is_custom: true,
    perm: 'settings.terms_cond',
  },

  // settings
  { heading: 'settings' },
  {
    title: 'unitCategories',
    to: { name: 'unit-categories' },
    icon: { icon: UnitCategoryIcon },
    is_custom: true,
    perm: 'unit_category',
  },
  {
    title: 'currencies',
    to: { name: 'currencies' },
    icon: { icon: CurrencyIcon },
    is_custom: true,
    perm: 'currency',
  },
  {
    title: 'locales',
    to: { name: 'locales' },
    icon: { icon: LocaleIcon },
    is_custom: true,
    perm: 'locale',
  },
  {
    title: 'countries',
    to: { name: 'countries' },
    icon: { icon: CountryIcon },
    is_custom: true,
    perm: 'country',
  },
  {
    title: 'settings',
    to: { name: 'settings' },
    icon: { icon: SettingsIcon },
    is_custom: true,
    perm: 'settings.general_settings',
  },

]

export default adminMenu
