<script setup>
import i18n from "@/plugins/i18n"
import { useHelperFunctions } from "@/composables/useHelperFunctions"

const props = defineProps({
  totalPlaced: {
    type: Number,
    default: 0,
  },
  totalPreparing: {
    type: Number,
    default: 0,
  },
  totalDelivered: {
    type: Number,
    default: 0,
  },
  totalCancelled: {
    type: Number,
    default: 0,
  },
})

const { t } = i18n.global

const { formatPrice } = useHelperFunctions()
</script>

<template>
  <VCard>
    <VCardText>
      <h6 class="text-h6 text-no-wrap">
        {{ t('amountOverview') }}
      </h6>
    </VCardText>

    <VCardText class="mt-2">
      <VRow>
        <VCol col="9">
          {{ t('placedSales') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ formatPrice(props.totalPlaced) }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('preparingSales') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ formatPrice(props.totalPreparing) }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('deliveredSales') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ formatPrice(props.totalDelivered) }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9">
          {{ t('cancelledSales') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ formatPrice(props.totalCancelled) }}
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
