import { BaseUrl } from "@/helpers/BaseUrl"
import { lsGet } from "@/helpers/localStorageHelper"
import adminAxios from "@/plugins/axios"
import { defineStore } from "pinia"

const baseRoleUrl = BaseUrl.get("role")

export const useRoleStore = defineStore("role", {
  state: () => {
    return {
      permissions: lsGet("permission") ? JSON.parse(lsGet("permission")) : [],
    }
  },
  getters: {
    getPermissions() {
      return this.permissions
    },
  },
  actions: {
    checkNavPerm(item) {
      let userData = lsGet("userData", true) || null
      if (!userData) {
        return false
      }
      if (userData.is_super_admin) {
        return true
      }

      let hasPerm = true

      if (item.perm) {
        let ps = item.perm.split(".")
        hasPerm = this.hasPerms(ps[0], ps[1] || "list")
      }

      if (item.perms) {
        hasPerm = item.perms.some(perm => {
          if (!userData) {
            return false
          }

          let ps = perm[0].split(".")

          return this.hasPerms(ps[0], ps[1] || "list")
        })
      }

      if (item.children) {
        hasPerm = item.children.some(i => {
          if (!userData) {
            return false
          }
          if (!i?.perm) {
            return true
          }
          
          let ps = i.perm.split(".")

          return this.hasPerms(ps[0], ps[1] || "list")
        })
      }

      return hasPerm
    },
    hasPerms(cls, perms) {
      let userData = lsGet("userData", true) || null
      if (userData.is_super_admin) {
        return true
      }

      if (!cls || !perms) {
        return true
      }
      let flag = false
      if (typeof perms == "string") {
        flag = this.permissions.indexOf(cls + "." + perms) > -1 ? true : false
      } else {
        if (perms.length == 0) {
          flag = true
        }
        perms.forEach(p => {
          if (this.permissions.indexOf(cls + "." + p) > -1) {
            flag = true
          }
        })
      }

      return flag
    },
    async getAllRoles() {
      try {
        const response = await adminAxios.get(`${baseRoleUrl}/all`)

        return response.data.data
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async createRole(payload) {
      try {
        const response = await adminAxios.post(`${baseRoleUrl}`, payload)

        return response.data.data
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateRole(id, uuid, payload) {
      try {
        const response = await adminAxios.put(
          `${baseRoleUrl}/${id}?${uuid}`,
          payload,
        )

        return response.data.data
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getRolePermissions(roleId) {
      try {
        const response = await adminAxios.get(`${baseRoleUrl}/${roleId}`)

        return response.data.data.permissions
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async getPermissionsSets() {
      try {
        const response = await adminAxios.get(`${baseRoleUrl}/permission-set`)

        return response.data.data
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async deleteRole(id, uuid) {
      try {
        const response = await adminAxios.delete(
          `${baseRoleUrl}/${id}?uuid=${uuid}`,
        )

        return response.data.data
      } catch (error) {
        return await Promise.reject(error)
      }
    },
    async updateRolePermissions(roleId, roleUuid, payload) {
      try {
        return await adminAxios.put(
          `${baseRoleUrl}/${roleId}/perm?uuid=${roleUuid}`,
          {
            permissions: payload,
          },
        )
      } catch (error) {
        return await Promise.reject(error)
      }
    },
  },
})
