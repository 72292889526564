<script setup>
import MostUsersOrders from '@/components/dashboard/MostUsersOrders.vue'
import OrdersIssues from '@/components/dashboard/OrdersIssues.vue'
import PopularProducts from '@/components/dashboard/PopularProducts.vue'
import SalesGrowth from '@/components/dashboard/SalesGrowth.vue'
import TopSellingProducts from '@/components/dashboard/TopSellingProducts.vue'
import TotalEarningsOverview from '@/components/dashboard/TotalEarningsOverview.vue'
import WebsiteAnalytics from '@/components/dashboard/WebsiteAnalytics.vue'

import AmountOverview from '@/components/dashboard/AmountOverview.vue'
import OrderOverview from '@/components/dashboard/OrderOverview.vue'
import ProductOverview from '@/components/dashboard/ProductOverview.vue'
import UserOverview from '@/components/dashboard/UserOverview.vue'

import DateRangeSelector from "@/components/selectors/DateRangeSelector.vue"

import { useDashboardStore } from "@/stores/dashboard"
import { useRoleStore } from "@/stores/role"
import { useSnackBarStore } from "@/stores/snackbar"

import RangeDateType from '@/constants/RangeDateType'
import { reactive } from "vue"

const roleStore = useRoleStore()
const dashboardStore = useDashboardStore()
const notification = useSnackBarStore()

const data = reactive({
  dashboard: {},
  dashboardLoading: false,
  filters: {
    date_range: RangeDateType.ALL.value,
  },
})

onMounted(async () => {
  getDashboardData()
})

const getDashboardData = () => {
  if( !roleStore.hasPerms('dashboard', 'get') ) {
    return
  }

  data.dashboardLoading = true
  dashboardStore.getDashboardData(data.filters)
    .then(res => {
      data.dashboard = res.data.data
    })
    .catch(notification.responseError)
    .finally(() => {
      data.dashboardLoading = false
    })
}

const selectDateRange = date => {
  data.filters.date_range = date
  getDashboardData()
}
</script>

<template>
  <ProgressBar v-if="data.dashboardLoading" />
  
  <div v-else>
    <VRow>
      <VCol
        cols="auto"
        class="mx-5"
      >
        <h3 class="pt-2">
          {{ $t('dashboard') }} 
          <VBtn
            icon="mdi-refresh"
            color="default"
            variant="text"
            class="ml-2"
            size="small"
            @click="getDashboardData"
          />
        </h3>
      </VCol>
      <VSpacer />
      <VCol
        cols="12" 
        sm="auto"
      >
        <DateRangeSelector
          :date-range="data.filters.date_range"
          @select-date-range="selectDateRange"
        />
      </VCol>
    </VRow>
    <VRow class="match-height">
      <VCol
        cols="12"
        md="6"
      >
        <WebsiteAnalytics 
          :total-orders="data.dashboard.total_orders"
          :total-customers="data.dashboard.total_clients"
          :total-active-orders="data.dashboard.total_active_orders"
          :total-active-issues="data.dashboard.total_reported_issues"
        />
      </VCol>

      <VCol
        cols="12"
        md="6"
      >
        <SalesGrowth
          :total-sales="data.dashboard.total_sales"
          :weekly-growth="data.dashboard.weekly_growth"
        />
      </VCol>
      <!-- totals -->
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <UserOverview 
          :total-new="data.dashboard.total_clients_new"
          :total-issue="data.dashboard.total_clients_issue"
          :total-order="data.dashboard.total_clients_order"
          :total-cart="data.dashboard.total_clients_cart"
        />
      </VCol>
      
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <OrderOverview 
          :total-delivered="data.dashboard.total_orders_delivered"
          :total-placed="data.dashboard.total_orders_placed"
          :total-preparing="data.dashboard.total_orders_preparing"
          :total-cancelled="data.dashboard.total_orders_cancelled"
        />
      </VCol>
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <ProductOverview 
          :total-pending="data.dashboard.total_products_pending"
          :total-coming-soon="data.dashboard.total_products_coming_soon"
          :total-available="data.dashboard.total_products_available"
          :total-hidden="data.dashboard.total_products_hidden"
        />
      </VCol>
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <AmountOverview 
          :total-placed="data.dashboard.total_sales_placed"
          :total-preparing-soon="data.dashboard.total_sales_preparing"
          :total-delivered="data.dashboard.total_sales_delivered"
          :total-cancelled="data.dashboard.total_sales_cancelled"
        />
      </VCol>
      <!-- -->

      <VCol
        cols="12"
        md="8"
      >
        <TotalEarningsOverview 
          :shop-yearly-growth="data.dashboard.shop_yearly_growth"
        />
      </VCol>

      <VCol
        cols="12"
        md="4"
      >
        <PopularProducts :popular-products="data.dashboard.popular_products" />
      </VCol>

      <VCol
        cols="12"
        md="6"
      >
        <TopSellingProducts :top-selling-products="data.dashboard.top_selling_products" />
      </VCol>
      <VCol
        cols="12"
        md="6"
      >
        <MostUsersOrders :most-users-orders="data.dashboard.most_users_orders" />
      </VCol>  
      <VCol
        cols="12"
      >
        <OrdersIssues :orders-issues="data.dashboard.latest_orders_issues" />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/libs/apex-chart.scss";
</style>
