<script setup>
import { useHelperFunctions } from "@/composables/useHelperFunctions"
import IssueStatus from "@/constants/IssueStatus"
import i18n from "@/plugins/i18n"
import { avatarText } from '@core/utils/formatters'

const props = defineProps({
  ordersIssues: {
    type: Array,
    default: ()=>[],
  },
})

const { t } = i18n.global

const { toPascalCase, formatDate } = useHelperFunctions()

const getStatusColor = status => {
  switch(status){
  case IssueStatus.INITIALIZED.value:
    return IssueStatus.INITIALIZED.color
  case IssueStatus.IN_PROGRESS.value:
    return IssueStatus.IN_PROGRESS.color
  case IssueStatus.SOLVED.value:
    return IssueStatus.SOLVED.color
  }
}

const getStatusVal = status => {
  switch(status){
  case IssueStatus.INITIALIZED.value:
    return toPascalCase(IssueStatus.INITIALIZED.text)
  case IssueStatus.IN_PROGRESS.value:
    return toPascalCase(IssueStatus.IN_PROGRESS.text)
  case IssueStatus.SOLVED.value:
    return toPascalCase(IssueStatus.SOLVED.text)
  }
}
</script>

<template>
  <VCard :title="t('ordersIssues')">
    <VDivider />
    <VTable class="text-no-wrap">
      <thead>
        <tr>
          <th class="font-weight-semibold">
            {{ t('customer') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('orderId') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('status') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('issue') }}
          </th>
          <th class="font-weight-semibold">
            {{ t('placedAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="issue in props.ordersIssues"
          :key="issue.id"
        >
          <td style="padding-block: 0.61rem;">
            <VRow
              class="ma-2"
              dense
              align="center"
            >
              <VCol cols="12">
                <div class="text-medium-emphasis font-weight-semibold text-base">
                  <VAvatar
                    v-if="issue.account.avatar"
                    size="34"
                    color="secondary"
                    variant="tonal"
                    :image="issue.account?.avatar?.thumb_url"
                  />
                  <VAvatar
                    v-else
                    size="34"
                    color="secondary"
                    variant="tonal"
                  >
                    <span
                      class="font-weight-semibold"
                    >
                      {{ avatarText(issue.account.name) }}
                    </span>
                  </VAvatar>
                </div>
              </VCol>
              <VCol cols="12">
                <div class="text-sm opacity-100 text-disabled">
                  {{ issue.account?.first_name }} {{ issue.account?.last_name }}
                </div>
              </VCol>
            </VRow>
          </td>
          <td style="padding-block: 0.61rem;">
            <div class="text-sm opacity-100 text-disabled">
              {{ issue?.order?.id }}
            </div>
          </td>
          <td style="padding-block: 0.61rem;">
            <VChip
              label
              :color="getStatusColor(issue.status)"
            >
              {{ getStatusVal(issue.status) }}
            </VChip>
          </td>
          <td style="padding-block: 0.61rem;">
            <div>
              <p class="text-medium-emphasis font-weight-semibold text-base mb-0">
                {{ issue?.description || "N/A" }}
              </p>
              <p class="text-sm mb-0 opacity-100 text-disabled">
                {{ issue?.predefined_issue?.name || "N/A" }}
              </p>
            </div>
          </td>
          <td style="padding-block: 0.61rem;">
            <div>
              <div
                class="text-medium-emphasis font-weight-semibold text-base mb-0"
                v-html="formatDate(issue.created_at, true)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </VTable>
  </VCard>
</template>
